import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext/UserContext";

/*
 * This component is used to display the protected route, which contains any internal pages outside the login page.
 * This protects components from being navigated to without the user beign authenticated.
 * It is used in the App component.
 */
const ProtectedRoute = () => {
  const { user } = useContext(UserContext);

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
