import { TableVirtuoso } from "react-virtuoso";
import { LogVirtuosoTableData } from "../Utils/tableDataFetch";
import { rowColumns } from "./ColumnsConfig";
import { TableHeader } from "./TableHeader";
import { TableRowContent } from "./TableRowContent";
import { Box, CircularProgress } from "@mui/material";

interface TableProps {
  filteredRows: LogVirtuosoTableData[];
  loading: boolean;
}

/*
 * This component is used to display the virtualized table on the Dashboard page.
 */
export const Table = ({ filteredRows, loading }: TableProps) => {
  const header = () => <TableHeader columns={rowColumns} />;
  const content = (_index: number, row: LogVirtuosoTableData) => (
    <TableRowContent row={row} columns={rowColumns} />
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <TableVirtuoso
      style={{
        overflow: "auto",
        minHeight: "675px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
      data={filteredRows}
      fixedHeaderContent={header}
      itemContent={content}
    />
  );
};
