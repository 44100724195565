import { Button, Box, useTheme } from "@mui/material";
import { AnalyticsOutlined } from "@mui/icons-material";

interface SummaryButtonProps {
  onClick: () => void;
}

const SummaryButton = ({ onClick }: SummaryButtonProps) => {
  const theme = useTheme();
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        mx: 1,
        color: theme.typography.button.color,
        backgroundColor: theme.palette.secondary.main,
        ":hover": {
          backgroundColor: theme.palette.secondary.main,
        },
        height: "50px",
      }}
      startIcon={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pb: "2px",
          }}
        >
          <AnalyticsOutlined />
        </Box>
      }
    >
      Summary
    </Button>
  );
};

export default SummaryButton;
