import { useTheme } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import DateRange from "@mui/icons-material/DateRange";

const FilterDatePicker = (props: DatePickerProps<Dayjs>) => {
  const theme = useTheme();
  return (
    <DatePicker
      {...props}
      components={{
        OpenPickerIcon: DateRange,
      }}
      slotProps={{
        textField: {
          sx: {
            "& .MuiOutlinedInput-root": {
              height: "40px",
            },

            "& .MuiInputLabel-root": {
              fontWeight: 700,
              color: theme.palette.text.primary,
              marginTop: "-6px",
              marginLeft: "-10px",
            },
            "& .MuiSvgIcon-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiInputBase-input": {
              fontSize: "12px",
            },
          },
          InputLabelProps: {
            shrink: true,
          },
          inputProps: {
            placeholder: "MM/DD/YYYY",
          },
        },
      }}
    />
  );
};

export default FilterDatePicker;
