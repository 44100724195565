import dayjs from 'dayjs';
import { User } from 'firebase/auth';
import { serviceUrl } from '../../../firebase';

export interface LogVirtuosoTableData {
  id: string;
  documentId: string;
  completedAt: number;
  device: string;
  status: string;
  userFriendlyStatus: string;
  submittedAt: number;
  appData: string;
  [key: string]: any;
}

/*
 * This function fetches the data from the log endpoint.
 * It returns the data and/or an error if there is one.
 * Used by dashboard at the moment to get the data
 */
export const fetchData = async (user: User | null) => {
  const firebaseIdToken = user ? await user.getIdToken() : null;

  try {
    const response = await fetch(serviceUrl + '/logs', {
      headers: { Authorization: `Bearer ${firebaseIdToken}` },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    const formattedData = data
      .map((row: LogVirtuosoTableData) => {
        const completedAt = row.completedAt ? dayjs(row.completedAt) : null;
        const submittedAt = row.submittedAt ? dayjs(row.submittedAt) : null;

        let status;
        let userFriendlyStatus;
        try {
          const statusJson = JSON.parse(row.status);
          if (!Array.isArray(statusJson)) {
            status = statusJson.status;
          } else if (statusJson.length === 0) {
            status = 'Unknown';
          } else {
            // For now just use the first index
            const firstIndex = statusJson[0];
            if (firstIndex.troubleshooterMessage) {
              status = firstIndex.troubleshooterMessage;
            }
            if (firstIndex.userMessage) {
              userFriendlyStatus = firstIndex.userMessage;
            }
          }
        } catch (error) {
          // This is assuming the JSON parse failed because the status type is just a string
          status = row.status;
          userFriendlyStatus = undefined;
        }

        return {
          ...row,
          device: row.device,
          status: status,
          userFriendlyStatus: userFriendlyStatus,
          completedAt:
            completedAt && completedAt.isValid() ? completedAt : null,
          submittedAt:
            submittedAt && submittedAt.isValid() ? submittedAt : null,
          appData: row.appData ? JSON.parse(row.appData) : null,
        };
      })
      .filter((row: LogVirtuosoTableData) => row.device !== 'node');

    return { data: formattedData, error: null };
  } catch (error) {
    console.error('Fetch error:', error);
    return { data: [], error: 'Failed to retrieve data' };
  }
};
