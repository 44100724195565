import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { getAuth, updatePassword } from "firebase/auth";
import { useContext, useState } from "react";
import { ThemeContext } from "../../contexts/ThemeContext/ThemeContext";

/*
 * This component is used to display the settings page, which contains the options to change password and theme.
 * It is used in the App component.
 */
function Settings() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [open, setOpen] = useState(false);

  const { themeMode, toggleTheme } = useContext(ThemeContext);

  const handleThemeChange = (event: SelectChangeEvent<string>) => {
    toggleTheme();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePasswordChange = async () => {
    if (password && confirmPassword && password === confirmPassword) {
      const auth = getAuth();
      const user = auth.currentUser;

      try {
        await updatePassword(user!, password);
        alert("Password changed successfully");
        setPassword("");
        setConfirmPassword("");
        handleClose();
      } catch (error) {
        console.error("Error changing password", error);
        alert("Error changing password");
      }
    } else {
      alert("Please make sure the passwords match");
    }
  };

  return (
    <Paper
      sx={{
        height: "93vh",
        width: "100%",
        padding: 0,
        borderRadius: 0,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? "#333" : "#f5f5f5",
      }}
    >
      <Container>
        <Box sx={{ pt: 5 }}>
          <Typography variant="h4" gutterBottom>
            Settings
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button variant="contained" onClick={handleOpen}>
                Change Password
              </Button>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                  <TextField
                    label="New Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                  />
                  <TextField
                    label="Confirm Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                    sx={{ mt: 1 }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handlePasswordChange}>Save</Button>
                </DialogActions>
              </Dialog>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">Theme</Typography>
              <FormControl fullWidth>
                <Select value={themeMode} onChange={handleThemeChange}>
                  <MenuItem value="light">Light</MenuItem>
                  <MenuItem value="dark">Dark</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Paper>
  );
}

export default Settings;
