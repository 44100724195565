import { TableColumn } from "../Dashboard";

/*
 * The columns that are displayed in the table.
 * The dataKey corresponds to the key in the data object from the retrieved jsonData.
 * This is used primarily for the DashboardTable and TableRowContent to define and format the columns.
 */

export const rowColumns: TableColumn[] = [
  {
    label: "Document ID",
    dataKey: "documentId",
    width: "200px",
  },
  { label: "Status", dataKey: "status", width: "300px" },
  {
    label: "Completed",
    dataKey: "completedAt",
    width: "115px",
  },
  {
    label: "Submitted At",
    dataKey: "submittedAt",
    width: "170px",
  },
  { label: "Device", dataKey: "device", width: "200px" },
  { label: "App Data", dataKey: "appData", width: "100%" },
];
