import { Button, Box, useTheme } from "@mui/material";
import { FileUploadOutlined } from "@mui/icons-material";

interface ExportButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const ExportButton = ({ onClick }: ExportButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        ml: 1,
        color: theme.typography.button.color,
        ":hover": {
          backgroundColor: theme.palette.primary.main,
        },
        height: "50px",
      }}
      startIcon={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pb: "2px",
          }}
        >
          <FileUploadOutlined />
        </Box>
      }
    >
      Export
    </Button>
  );
};

export default ExportButton;
