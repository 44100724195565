import { Box, FormControlLabel, List, ListItem, Popover } from "@mui/material";

import { rowColumns } from "../../ColumnsConfig";
import CustomCheckbox from "../../CustomCheckbox";

interface ColumnFilterPopoverProps {
  columnAnchorEl: HTMLElement | null;
  handleColumnClose: () => void;
  handleColumnSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedColumns: { [key: string]: boolean };
}

/*
 * This component is used to display the column filter popover to choose which columns the search bar will search in.
 * It is used in the TableOptions component.
 */
const ColumnFilterPopover: React.FC<ColumnFilterPopoverProps> = ({
  columnAnchorEl,
  handleColumnClose,
  handleColumnSelect,
  selectedColumns,
}) => {
  return (
    <Popover
      anchorEl={columnAnchorEl}
      open={!!columnAnchorEl}
      onClose={handleColumnClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: "4px",
            pt: "8px",
            pb: "8px",
          }}
        >
          {rowColumns
            .filter(
              (column) =>
                column.dataKey !== "completedAt" &&
                column.dataKey !== "submittedAt"
            )
            .map((column) => (
              <ListItem key={column.dataKey} sx={{ pb: "0px", pt: "0px" }}>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={selectedColumns[column.dataKey]}
                      onChange={handleColumnSelect}
                      name={column.dataKey}
                      indeterminate={false}
                    />
                  }
                  label={column.label}
                  sx={{ marginRight: "0px" }}
                />
              </ListItem>
            ))}
        </List>
      </Box>
    </Popover>
  );
};

export default ColumnFilterPopover;
