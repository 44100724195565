import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Popover,
} from "@mui/material";
import { convertToCSV, downloadCSV } from "../../../Utils/formatting";

interface ExportPopoverProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  dataToExport: { lineData: any }; // Add this line
}

/*
 * This component is used to export the graph data to a CSV file.
 * It is used in the SummaryGraphs component.
 */
const ExportGraphPopover: React.FC<ExportPopoverProps> = ({
  anchorEl,
  onClose,
  dataToExport,
}) => {
  const handleExport = () => {
    const data = [...dataToExport.lineData];
    const csvData = convertToCSV(data);

    downloadCSV(csvData, "data");
    onClose();
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <List sx={{ pt: 0, pb: 0 }}>
          <ListItemButton onClick={handleExport}>
            <ListItemText primary="Export Graph Data" />
          </ListItemButton>
        </List>
      </Box>
    </Popover>
  );
};

export default ExportGraphPopover;
