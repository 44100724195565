import {
    Box,
    IconButton,
    TableCell,
    TableRow,
    TableSortLabel,
    useTheme,
  } from "@mui/material";
  import { useContext } from "react";
  import { DashboardContext } from "../../../contexts/DashboardContext/DashboardContext";
  import { TableColumn } from "../Dashboard";
  import { handleSort } from "../Utils/filterSearch";
  import { Refresh } from "@mui/icons-material";
  
  interface TableHeaderProps {
    columns: TableColumn[];
  }
  
  /*
   * This component is used to display the table header, which contains the column labels to change between asc/desc.
   * It is used in the DashboardTable component to define the table header.
   */
  export const TableHeader = ({ columns }: TableHeaderProps) => {
    const { sortConfig, setSortConfig, setSortedColumn } =
      useContext(DashboardContext);
  
    const handleSortClick = (column: TableColumn) => {
      handleSort(column, setSortedColumn, sortConfig, setSortConfig);
    };
  
    const theme = useTheme();
  
    return (
      <TableRow>
        <TableCell variant="head" sx={{ width: "10px", padding: 2 }}></TableCell>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.numeric ? "right" : "left"}
            sx={{
              width: column.width,
              color: theme.palette.text.secondary,
              top: 0,
            }}
          >
            <Box display="flex" alignItems="center">
              <TableSortLabel
                active={sortConfig?.key === column.dataKey}
                direction={
                  sortConfig?.key === column.dataKey
                    ? sortConfig.direction
                    : undefined
                }
                onClick={() => handleSortClick(column)}
              >
                {column.label}
              </TableSortLabel>
            </Box>
          </TableCell>
        ))}
      </TableRow>
    );
  };
  