import Dayjs from "dayjs";
import { LogVirtuosoTableData } from "./tableDataFetch";

/*
 * This function processes the data for the line graph for the errorsuccess view for the SummaryGraph component.
 */
export function processErrorSuccessLine(
  data: LogVirtuosoTableData[]
): { date: Date; [key: string]: number | Date }[] {
  const counts: {
    [key: string]: { total: number; succeeded: number; failed: number };
  } = {};

  data.forEach((row) => {
    const day = Dayjs(row.submittedAt).format("YYYY-MM-DD");
    if (!counts[day]) {
      counts[day] = { total: 0, succeeded: 0, failed: 0 };
    }
    counts[day].total += 1;
    if (row.status === "succeeded") {
      counts[day].succeeded += 1;
    } else {
      counts[day].failed += 1;
    }
  });

  const sortedData = Object.keys(counts)
    .map((day) => ({
      date: Dayjs(day).toDate(),
      ...counts[day],
    }))
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  return sortedData;
}

/*
 * This function processes the data for the line graph for the incorrectforms view for the SummaryGraph component.
 */
export function processIncorrectFormsLine(
  data: LogVirtuosoTableData[]
): { date: Date; [key: string]: number | Date }[] {
  const counts: {
    [key: string]: {
      total: number;
      succeeded: number;
      otherErrors: number;
      incorrectForms: number;
    };
  } = {};

  data.forEach((row) => {
    const day = Dayjs(row.submittedAt).format("YYYY-MM-DD");
    if (!counts[day]) {
      counts[day] = {
        total: 0,
        succeeded: 0,
        otherErrors: 0,
        incorrectForms: 0,
      };
    }
    counts[day].total += 1;
    if (row.status === "succeeded") {
      counts[day].succeeded += 1;
    } else if (Array.isArray(row.status)) {
      if (
        row.status.some((error) =>
          error.toLowerCase().includes("unsupported form")
        )
      ) {
        counts[day].incorrectForms += 1;
      } else {
        counts[day].otherErrors += 1;
      }
    } else {
      counts[day].otherErrors += 1;
    }
  });

  const sortedData = Object.keys(counts)
    .map((day) => ({
      date: Dayjs(day).toDate(),
      ...counts[day],
    }))
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  return sortedData;
}

/*
 * This function generates the series for the line graphs.
 */
export function generateSeriesConfigLine(
  data: LogVirtuosoTableData[],
  view: string
) {
  if (view === "errortypes" && data[0]) {
    return [
      {
        dataKey: "total",
        label: "Total Documents",
        color: "blue",
        showMark: true,
      },
    ];
  } else if (view === "errorsuccess") {
    return [
      {
        dataKey: "total",
        label: "Total Documents",
        color: "blue",
        showMark: true,
        curve: "linear",
      },
      {
        dataKey: "succeeded",
        label: "Succeeded",
        color: "green",
        showMark: true,
        curve: "linear",
      },
      {
        dataKey: "failed",
        label: "Failed",
        color: "red",
        showMark: true,
        curve: "linear",
      },
    ];
  } else if (view === "incorrectforms") {
    return [
      {
        dataKey: "total",
        label: "Total Documents",
        color: "blue",
        showMark: true,
        curve: "linear",
      },
      {
        dataKey: "succeeded",
        label: "Succeeded",
        color: "green",
        showMark: true,
        curve: "linear",
      },
      {
        dataKey: "incorrectForms",
        label: "Incorrect Forms",
        color: "orange",
        showMark: true,
        curve: "linear",
      },
      {
        dataKey: "otherErrors",
        label: "Other Errors",
        color: "red",
        showMark: true,
        curve: "linear",
      },
    ];
  }
}

/*
 * This function processes the data for the pie graph for the errorsuccess view for the SummaryGraph component.
 */
export function processIncorrectFormsGraph(
  data: LogVirtuosoTableData[]
): { id: number; value: number; label: string }[] {
  let succeeded = 0;
  let failed = 0;
  let incorrectForm = 0;

  data.forEach((row) => {
    if (row.status === "succeeded") {
      succeeded += 1;
    } else if (Array.isArray(row.status)) {
      if (
        row.status.some((error) =>
          error.toLowerCase().includes("unsupported form")
        )
      ) {
        incorrectForm += 1;
      } else {
        failed += 1;
      }
    } else {
      failed += 1;
    }
  });

  return [
    { id: 0, value: succeeded, label: "Succeeded" },
    { id: 1, value: incorrectForm, label: "Incorrect Form" },
    { id: 2, value: failed, label: "Other Errors" },
  ];
}

/*
 * This function processes the data for the pie graph for the errorsuccess view for the SummaryGraph component.
 */
export function processErrorSuccessGraph(
  data: LogVirtuosoTableData[]
): { id: number; value: number; label: string }[] {
  let succeeded = 0;
  let failed = 0;

  data.forEach((row) => {
    if (row.status === "succeeded") {
      succeeded += 1;
    } else {
      failed += 1;
    }
  });

  return [
    { id: 0, value: succeeded, label: "Succeeded" },
    { id: 1, value: failed, label: "Failed" },
  ];
}
