import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useContext } from "react";
import { FilterOptionsContext } from "../../../../../contexts/FilterOptionsContext/FilterOptionsContext";
import CustomCheckbox from "../../CustomCheckbox";
import FilterDatePicker from "../FilterDatePicker";
import { HeaderTypography } from "../../../../../App";
const FilterOptionsPopover = () => {
  const {
    filters,
    setFilters,
    clearFilters,
    filterAnchorEl,
    handleFilterClose,
    handleStartDateChangeCompleted,
    handleEndDateChangeCompleted,
    handleStartDateChangeSubmitted,
    handleEndDateChangeSubmitted,
    startDateCompleted,
    endDateCompleted,
    startDateSubmitted,
    endDateSubmitted,
  } = useContext(FilterOptionsContext);

  const theme = useTheme();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [event.target.name as keyof typeof prevFilters]: event.target.checked,
    }));
  };

  const resetDatePicker = (type: string) => {
    if (type === "completed") {
      handleStartDateChangeCompleted(null);
      handleEndDateChangeCompleted(null);
    } else {
      handleStartDateChangeSubmitted(null);
      handleEndDateChangeSubmitted(null);
    }
  };

  return (
    <Popover
      open={!!filterAnchorEl}
      anchorEl={filterAnchorEl}
      onClose={handleFilterClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box width={350}>
        <HeaderTypography
          sx={{
            p: 2,
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          Filter
        </HeaderTypography>
        <Divider flexItem sx={{ width: "100%", mb: 2 }} />
        <Grid container>
          <Grid xs={12} sx={{ pl: 2, pb: 1 }}>
            <Typography>Status Type</Typography>
          </Grid>
          <Grid xs={6} sx={{ pl: 2 }}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={filters.success}
                  onChange={handleCheckboxChange}
                  name="success"
                  indeterminate={false}
                />
              }
              label="Success"
            />
          </Grid>
          <Grid xs={6} sx={{ pl: 2 }}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={filters.errors}
                  onChange={handleCheckboxChange}
                  name="errors"
                  indeterminate={false}
                />
              }
              label="Errors"
            />
          </Grid>
          <Divider flexItem sx={{ width: "100%", mb: 2, mt: 1 }} />

          <Grid xs={9} sx={{ pl: 2, pb: 2 }}>
            <Typography>Submitted At</Typography>
          </Grid>
          <Grid xs={3} sx={{ pl: 2 }}>
            <Button
              disableRipple
              color="primary"
              sx={{
                pt: 0.25,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => resetDatePicker("submitted")}
            >
              <Typography variant="body2">Reset</Typography>
            </Button>
          </Grid>
          <Grid xs={6} sx={{ p: 2, pr: 1 }}>
            <FilterDatePicker
              label="From:"
              value={startDateSubmitted}
              onChange={handleStartDateChangeSubmitted}
            />
          </Grid>
          <Grid xs={6} sx={{ p: 2, pl: 1 }}>
            <FilterDatePicker
              label="To:"
              value={endDateSubmitted}
              onChange={handleEndDateChangeSubmitted}
            />
          </Grid>
          <Divider flexItem sx={{ width: "100%", mt: 1, mb: 2 }} />
          <Grid xs={9} sx={{ pl: 2, pb: 2 }}>
            <Typography>Completed At</Typography>
          </Grid>
          <Grid xs={3} sx={{ pl: 2 }}>
            <Button
              disableRipple
              color="primary"
              sx={{
                pt: 0.25,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => resetDatePicker("completed")}
            >
              <Typography variant="body2">Reset</Typography>
            </Button>
          </Grid>
          <Grid xs={6} sx={{ p: 2, pr: 1 }}>
            <FilterDatePicker
              label="From:"
              value={startDateCompleted}
              onChange={handleStartDateChangeCompleted}
            />
          </Grid>
          <Grid xs={6} sx={{ p: 2, pl: 1 }}>
            <FilterDatePicker
              label="To:"
              value={endDateCompleted}
              onChange={handleEndDateChangeCompleted}
            />
          </Grid>

          <Divider flexItem sx={{ width: "100%", mt: 1, mb: 1 }} />

          <Grid
            xs={12}
            sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              onClick={clearFilters}
              sx={{
                borderRadius: "10px",
                mr: 1,
                bgcolor: "white",
                color: theme.palette.secondary.main,
                border: "1px solid black",
              }}
            >
              Reset All
            </Button>
            <Button
              variant="contained"
              onClick={handleFilterClose}
              sx={{
                borderRadius: "10px",
                color: theme.palette.text.secondary,
                "&:hover": { bgcolor: theme.palette.primary.main },
              }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
};

export default FilterOptionsPopover;
