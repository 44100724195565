import {
  Box,
  FormControlLabel,
  List,
  ListItem,
  Popover,
  Radio,
  useTheme,
} from "@mui/material";

import { TableColumn } from "../../../Dashboard";
import { rowColumns } from "../../ColumnsConfig";

interface SortedColumnPopoverProps {
  sortedColumnAnchorEl: HTMLElement | null;
  handleSortedColumnClose: () => void;
  setSortedColumn: React.Dispatch<React.SetStateAction<TableColumn | null>>;
  sortedColumn: TableColumn | null;
  sortConfig: { key: string | null; direction: "asc" | "desc" };
  setSortConfig: React.Dispatch<
    React.SetStateAction<{ key: string | null; direction: "asc" | "desc" }>
  >;
}

/*
 * This component is used to display the sorted column popover, to choose which column is being sorted asc/desc.
 * It is used in the TableOptions component.
 */
const SortedColumnPopover: React.FC<SortedColumnPopoverProps> = ({
  sortedColumnAnchorEl,
  handleSortedColumnClose,
  setSortedColumn,
  sortedColumn,
  sortConfig,
  setSortConfig,
}) => {
  const theme = useTheme();
  return (
    <Popover
      open={!!sortedColumnAnchorEl}
      anchorEl={sortedColumnAnchorEl}
      onClose={handleSortedColumnClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <List component="nav">
          {rowColumns.map((column) => (
            <ListItem key={column.dataKey} sx={{ py: 0.5 }}>
              <FormControlLabel
                control={
                  <Radio
                    checked={column.dataKey === sortedColumn?.dataKey}
                    onChange={() => {
                      setSortedColumn(column);
                      setSortConfig({
                        key: column.dataKey,
                        direction: sortConfig.direction,
                      });
                      handleSortedColumnClose();
                    }}
                    sx={{
                      color: theme.palette.text.primary,
                      "&.Mui-checked": {
                        color: theme.palette.text.primary,
                      },
                    }}
                  />
                }
                label={column.label}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Popover>
  );
};

export default SortedColumnPopover;
