// firebase.ts
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCzv81HhGiWjkj-yyCvu_P-mjlXk1_8wcs',
  authDomain: 'd5000004990.firebaseapp.com',
  databaseURL: 'https://d5000004990-default-rtdb.firebaseio.com',
  projectId: 'd5000004990',
  storageBucket: 'd5000004990.appspot.com',
  messagingSenderId: '178315570807',
  appId: '1:178315570807:web:402b33304c137d02fe6072',
};

const customerConfig = {
  apiKey: 'AIzaSyAmFi6dQq6wD_ou4YNCVTawn6b_Hxk68fU',
  authDomain: 'o5000005205dev.firebaseapp.com',
  databaseURL: 'https://o5000005205dev-default-rtdb.firebaseio.com',
  projectId: 'o5000005205dev',
  storageBucket: 'o5000005205dev.appspot.com',
  messagingSenderId: '378670936863',
  appId: '1:378670936863:web:0aaa3fa6e7867fcfa3362a',
};

const configs: Record<string, any> = {
  ucinn: {
    url: 'https://cust-ucin-docknow-dev.azurewebsites.net/',
    config: customerConfig,
  },
  dev: {
    url: 'https://ony-docknow-service.azurewebsites.net',
    config: firebaseConfig,
  },
};

const customer = process.env.REACT_APP_CUSTOMER ?? 'dev';
const app = initializeApp(configs[customer].config ?? firebaseConfig);
const auth = getAuth(app);
const serviceUrl =
  configs[customer].url ?? 'https://ony-docknow-service.azurewebsites.net';
export { app, auth, serviceUrl };
