import { Button, Box, useTheme } from "@mui/material";
import { FilterAltOutlined } from "@mui/icons-material";

interface FilterButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const FilterButton = ({ onClick }: FilterButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      startIcon={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pb: "2px",
          }}
        >
          <FilterAltOutlined />
        </Box>
      }
      sx={{ color: theme.palette.text.primary, pr: 4 }}
      onClick={onClick}
    >
      Filter
    </Button>
  );
};

export default FilterButton;
