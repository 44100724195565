import { Tooltip, IconButton, useTheme } from "@mui/material";
import {
  KeyboardDoubleArrowDownOutlined,
  KeyboardDoubleArrowUpOutlined,
} from "@mui/icons-material";

interface SortIconButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  sortConfig: { direction: string };
}

const SortIconButton = ({ onClick, sortConfig }: SortIconButtonProps) => {
  const theme = useTheme();

  return (
    <Tooltip
      title={sortConfig.direction === "asc" ? "Ascending" : "Descending"}
    >
      <IconButton
        onClick={onClick}
        sx={{
          bgcolor: theme.palette.secondary.main,

          width: "35px",
          height: "35px",
          mr: 1,
          "&:hover": {
            bgcolor: theme.palette.secondary.main,
          },
          borderRadius: 3,
        }}
      >
        {sortConfig.direction === "desc" ? (
          <KeyboardDoubleArrowDownOutlined
            sx={{ color: theme.typography.button.color }}
          />
        ) : (
          <KeyboardDoubleArrowUpOutlined
            sx={{ color: theme.typography.button.color }}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default SortIconButton;
