//just put this in here in case we need to remember the user email that was used to login for any reason
import { User } from "firebase/auth";
import React from "react";

interface UserContextProps {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

/*
 * This context is used to pass the user state to primarily the App/ProtectedRoute so that it can redirect to the login page if the user is not logged in.
 * It is used in the App component.
 */
export const UserContext = React.createContext<UserContextProps>({
  user: null,
  setUser: () => {},
});
