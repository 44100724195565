import React from "react";

/*
 * This context is used to pass the theme state to the components that need it.
 * It is used in the App component.
 */
export const ThemeContext = React.createContext<{
  themeMode: "light" | "dark";
  toggleTheme: () => void;
}>({
  themeMode: "light",
  toggleTheme: () => {},
});
