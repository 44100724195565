import { LogVirtuosoTableData } from "./tableDataFetch";

/*
 * This function formats the date from a timestamp to a string.
 */
export const formatDate = (timestamp: number): string => {
  if (timestamp === null) return "N/A";
  const date = new Date(timestamp);
  if (date.toString() === "Invalid Date") return "N/A";
  return date.toLocaleString();
};

/*
 * This function converts the jsonData to a csv string for exporting
 */
export function convertToCSV(jsonData: LogVirtuosoTableData[]): string {
  const headers = Object.keys(jsonData[0]);
  const csvRows: string[] = [];

  csvRows.push(headers.join(","));

  for (const row of jsonData) {
    const values = headers.map((header) => {
      const escaped = ("" + row[header as keyof LogVirtuosoTableData]).replace(
        /"/g,
        '\\"'
      );
      return `"${escaped}"`;
    });
    csvRows.push(values.join(","));
  }

  return csvRows.join("\n");
}

/*
 * This function downloads the csv to the users computer using the csv string converted in convertToCSV
 */
export function downloadCSV(
  csvData: string,
  filename: string = "data.csv"
): void {
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}
