import { DefaultizedPieValueType } from "@mui/x-charts";
import { PieChart } from "@mui/x-charts/PieChart";
import {
  processErrorSuccessGraph,
  processIncorrectFormsGraph,
} from "../../../../Utils/graphFormatting";
import { LogVirtuosoTableData } from "../../../../Utils/tableDataFetch";

interface PieGraphProps {
  data: LogVirtuosoTableData[];
  dataView: string;
}

/*
 * This component is used to display the pie graph.
 * It is used in the SummaryGraphs component.
 */
export default function PieGraph({ data, dataView }: PieGraphProps) {
  let processedData: { id: number; value: number; label: string }[];
  if (dataView === "errorsuccess") {
    processedData = processErrorSuccessGraph(data);
  } else if (dataView === "incorrectforms") {
    processedData = processIncorrectFormsGraph(data);
  } else {
    processedData = [];
  }

  const TOTAL = processedData
    .map((item) => item.value)
    .reduce((a, b) => a + b, 0);

  const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / TOTAL;
    if (percent === 0) {
      return "";
    }
    return `${(percent * 100).toFixed(0)}%`;
  };
  return (
    <PieChart
      series={[
        {
          data: processedData,
          arcLabel: getArcLabel,
          innerRadius: "75%",
        },
      ]}
      height={300}
      margin={{ bottom: 40, left: 40, right: 40 }}
      slotProps={{
        legend: {
          direction: "row",
          position: { vertical: "bottom", horizontal: "middle" },
          padding: 0,
        },
      }}
    />
  );
}
