import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Popover,
} from "@mui/material";
import { convertToCSV, downloadCSV } from "../../../Utils/formatting";
import { LogVirtuosoTableData } from "../../../Utils/tableDataFetch";

interface ExportPopoverProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  rows: LogVirtuosoTableData[];
  filteredRows: LogVirtuosoTableData[];
  selectedRows: LogVirtuosoTableData[];
}

/*
 * This component is used to export the table data to a CSV file choosing between a variety of export options.
 * It is used in the TableOptions component.
 */
const ExportPopover: React.FC<ExportPopoverProps> = ({
  anchorEl,
  onClose,
  rows,
  filteredRows,
  selectedRows,
}) => {
  const handleExport = (exportRows: LogVirtuosoTableData[]) => {
    const csvData = convertToCSV(exportRows);
    downloadCSV(csvData);
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <List sx={{ pt: 0, pb: 0 }}>
          <ListItemButton onClick={() => handleExport(rows)}>
            <ListItemText primary="Export Entire Table" />
          </ListItemButton>
          <ListItemButton onClick={() => handleExport(selectedRows)}>
            <ListItemText primary="Export Selected Rows" />
          </ListItemButton>
          <ListItemButton onClick={() => handleExport(filteredRows)}>
            <ListItemText primary="Export All Filtered Rows" />
          </ListItemButton>
        </List>
      </Box>
    </Popover>
  );
};

export default ExportPopover;
