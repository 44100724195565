import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  browserSessionPersistence,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext/UserContext";
import { auth } from "../../firebase";
function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://onymos.com">
        Onymos
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

/*
 * This component is used to display the login page, which contains the login form.
 * It is used in the App component.
 */
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [navigate, user]);

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!email || !password) {
      alert("Please fill in both fields");
      return;
    }
    try {
      if (remember) {
        setPersistence(auth, browserSessionPersistence);
      }
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user);
    } catch (error) {
      alert(error);
    }
  };

  const handleResetPassword = async () => {
    if (!email) {
      alert("Please enter an email address.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email).then(() => {
        alert("Password reset email sent!");
      });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        maxWidth="400px"
      >
        <Grid xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12}>
          <form onSubmit={handleLogin}>
            <TextField
              autoFocus
              fullWidth
              margin="normal"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              placeholder="Email"
            />
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              placeholder="Password"
              type="password"
            />

            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  checked={remember}
                  onChange={(e) => setRemember(e.target.checked)}
                  sx={{
                    color: "black", // Change this to any color you want
                  }}
                />
              }
              label="Remember Me"
              sx={{
                mt: 0,
                mb: 1,
              }}
            />

            <Button type="submit" fullWidth variant="contained" color="primary">
              Submit
            </Button>
          </form>
          <Link
            component="button"
            variant="body2"
            underline="hover"
            onClick={handleResetPassword}
            sx={{ mt: 2 }}
          >
            Forgot password?
          </Link>
        </Grid>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Grid>
    </Grid>
  );
}
export default Login;
