import { LineChart } from "@mui/x-charts/LineChart";
import Dayjs from "dayjs";
import {
  generateSeriesConfigLine,
  processErrorSuccessLine,
  processIncorrectFormsLine,
} from "../../../../Utils/graphFormatting";
import { LogVirtuosoTableData } from "../../../../Utils/tableDataFetch";

interface LineGraphProps {
  data: LogVirtuosoTableData[];
  dataView: string;
}

/*
 * This component is used to display the line graph.
 * It is used in the SummaryGraphs component.
 */
export default function LineGraph({ data, dataView }: LineGraphProps) {
  let processedData;
  if (dataView === "errorsuccess") {
    const sortedData = processErrorSuccessLine(data);
    processedData = sortedData;
  } else if (dataView === "errortypes") {
  } else if (dataView === "incorrectforms") {
    const sortedData = processIncorrectFormsLine(data);
    processedData = sortedData;
  }

  const seriesConfig = generateSeriesConfigLine(data, dataView) || [];

  return (
    <LineChart
      xAxis={[
        {
          dataKey: "date",
          valueFormatter: (v) => Dayjs(v).format("YYYY-MM-DD"),
          scaleType: "time",
        },
      ]}
      series={seriesConfig}
      dataset={processedData}
      height={300}
      margin={{ top: 5 }}
      slotProps={{
        legend: {
          direction: "row",
          position: { vertical: "bottom", horizontal: "middle" },
          padding: 0,
        },
      }}
      sx={{
        ".MuiChartsTooltip-table": {
          color: "black",
          backgroundColor: "white",
        },
      }}
    />
  );
}
