import React, { ChangeEvent, MouseEvent } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Search, ExpandMore, ExpandLess } from "@mui/icons-material";

interface SearchBoxProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onColumnClick: (event: MouseEvent<HTMLButtonElement>) => void;
  columnAnchorEl: null | HTMLElement;
}

const SearchBox = ({
  value,
  onChange,
  onColumnClick,
  columnAnchorEl,
}: SearchBoxProps) => {
  return (
    <TextField
      name="Search"
      variant="outlined"
      value={value}
      onChange={onChange}
      placeholder="Search here..."
      sx={{
        mx: 1,
        width: "210px",
        "& .MuiOutlinedInput-root": {
          height: "50px",
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search color="inherit" />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton onClick={onColumnClick}>
            {columnAnchorEl ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        ),
      }}
    />
  );
};

export default SearchBox;
