import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";

import Grid from "@mui/material/Unstable_Grid2";

import { Close } from "@mui/icons-material";

import { Dayjs } from "dayjs";
import { DashboardContext } from "../../../../contexts/DashboardContext/DashboardContext";
import { FilterOptionsContext } from "../../../../contexts/FilterOptionsContext/FilterOptionsContext";
import { applyFiltersAndSearch, handleSearch } from "../../Utils/filterSearch";
import { LogVirtuosoTableData } from "../../Utils/tableDataFetch";
import { rowColumns } from "../ColumnsConfig";
import CustomCheckbox from "../CustomCheckbox";
import ColumnFilterPopover from "./Popovers/ColumnFilterPopover";
import ExportPopover from "./Popovers/ExportPopover";
import FilterOptionsPopover from "./Popovers/FilterOptionsPopover";
import SortedColumnPopover from "./Popovers/SortedColumnPopover";
import SummaryGraph from "./Summary/Summary";
import SearchBox from "./SearchBox";
import SummaryButton from "./Buttons/Summary";
import ExportButton from "./Buttons/Export";
import FilterButton from "./Buttons/Filter";
import SortButton from "./Buttons/Sort";
import SortIconButton from "./Buttons/SortIcon";
import { HeaderTypography } from "../../../../App";
import RefreshIconButton from "./Buttons/Refresh";
interface ControlProps {
  rows: LogVirtuosoTableData[];
  height: string;
  handleRefresh: () => void;
}

/*
 * This component is used to display all the different table options above the virtualized table which contains options for
 * exporting the table data, filtering the table data, searching it, etc.
 * It is used in the Dashboard component.
 */
const Controls = ({ rows, height, handleRefresh }: ControlProps) => {
  const [searchInput, setSearchInput] = useState("");

  const [filters, setFilters] = useState({
    success: false,
    errors: false,
    last1DayCompleted: false,
    last7DaysCompleted: false,
    last1DaySubmitted: false,
    last7DaysSubmitted: false,
  });

  const clearFilters = () => {
    setSearchInput("");
    setStartDateCompleted(null);
    setEndDateCompleted(null);
    setStartDateSubmitted(null);
    setEndDateSubmitted(null);
    setFilters({
      success: false,
      errors: false,
      last1DayCompleted: false,
      last7DaysCompleted: false,
      last1DaySubmitted: false,
      last7DaysSubmitted: false,
    });
  };

  const [isGraphOpen, setIsGraphOpen] = useState(false);

  const openGraph = () => setIsGraphOpen(true);
  const closeGraph = () => setIsGraphOpen(false);

  const { sortConfig, setSortConfig } = useContext(DashboardContext);
  const { selectedRows, setSelectedRows } = useContext(DashboardContext);
  const { sortedColumn, setSortedColumn } = useContext(DashboardContext);
  const { filteredRows, setFilteredRows } = useContext(DashboardContext);

  const [startDateCompleted, setStartDateCompleted] = useState<Dayjs | null>(
    null
  );
  const [endDateCompleted, setEndDateCompleted] = useState<Dayjs | null>(null);
  const [startDateSubmitted, setStartDateSubmitted] = useState<Dayjs | null>(
    null
  );
  const [endDateSubmitted, setEndDateSubmitted] = useState<Dayjs | null>(null);
  const [sortedColumnAnchorEl, setSortedColumnAnchorEl] =
    useState<null | HTMLElement>(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [columnAnchorEl, setColumnAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const seletedRowsData = rows.filter((row) => selectedRows.has(row.id));

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };
  const handleColumnClick = (event: React.MouseEvent<HTMLElement>) => {
    setColumnAnchorEl(event.currentTarget);
  };
  const handleColumnClose = () => {
    setColumnAnchorEl(null);
  };
  const handleSortClick = (event: React.MouseEvent<HTMLElement>) => {
    setSortConfig({
      key: sortConfig.key,
      direction: sortConfig.direction === "asc" ? "desc" : "asc",
    });
  };

  const handleExportClick = (event: React.MouseEvent<HTMLElement>) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const handleStartDateChangeCompleted = (date: Dayjs | null) => {
    setStartDateCompleted(date);
  };
  const handleEndDateChangeCompleted = (date: Dayjs | null) => {
    setEndDateCompleted(date);
  };
  const handleStartDateChangeSubmitted = (date: Dayjs | null) => {
    setStartDateSubmitted(date);
  };
  const handleEndDateChangeSubmitted = (date: Dayjs | null) => {
    setEndDateSubmitted(date);
  };
  const handleSortedColumnClick = (event: React.MouseEvent<HTMLElement>) => {
    setSortedColumnAnchorEl(event.currentTarget);
  };
  const handleSortedColumnClose = () => {
    setSortedColumnAnchorEl(null);
  };

  const [selectedColumns, setSelectedColumns] = useState<{
    [key: string]: boolean;
  }>(
    rowColumns.reduce((acc, column) => ({ ...acc, [column.dataKey]: true }), {})
  );
  const handleColumnSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedColumns({
      ...selectedColumns,
      [event.target.name]: event.target.checked,
    });
  };
  const handleSelectAll = () => {
    if (selectedRows.size > 0) {
      setSelectedRows(new Set());
    } else {
      const newSelectedRows = new Set(filteredRows.map((row) => row.id));
      setSelectedRows(newSelectedRows);
    }
  };
  const applyFiltersAndSorting = useCallback(() => {
    const newFilteredRows = applyFiltersAndSearch(
      rows,
      searchInput,
      sortConfig,
      startDateCompleted,
      endDateCompleted,
      startDateSubmitted,
      endDateSubmitted,
      selectedColumns,
      filters
    );

    setFilteredRows(newFilteredRows);
  }, [
    rows,
    setFilteredRows,
    searchInput,
    sortConfig,
    startDateCompleted,
    endDateCompleted,
    startDateSubmitted,
    endDateSubmitted,
    selectedColumns,
    filters,
  ]);

  const handleClickSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(setSearchInput, applyFiltersAndSorting, event);
  };

  useEffect(() => {
    applyFiltersAndSorting();
  }, [applyFiltersAndSorting]);

  return (
    <FilterOptionsContext.Provider
      value={{
        startDateCompleted,
        handleStartDateChangeCompleted,
        endDateCompleted,
        handleEndDateChangeCompleted,
        startDateSubmitted,
        handleStartDateChangeSubmitted,
        endDateSubmitted,
        handleEndDateChangeSubmitted,
        filters,
        setFilters,
        clearFilters,
        filterAnchorEl,
        handleFilterClose,
      }}
    >
      <Grid
        container
        alignItems="center"
        xs={12}
        sx={{ height: height, mt: 3, minHeight: "125px" }}
      >
        <Grid xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <HeaderTypography variant="h2" sx={{ mr: 5 }}>
            LogViewer
          </HeaderTypography>
        </Grid>
        <Grid
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box>
            <SearchBox
              value={searchInput}
              onChange={handleClickSearch}
              onColumnClick={handleColumnClick}
              columnAnchorEl={columnAnchorEl}
            />
            <SummaryButton onClick={openGraph} />
            <ExportButton onClick={handleExportClick} />
          </Box>
        </Grid>

        <Divider flexItem sx={{ width: "100%", mb: 2 }} />

        {/* Select All Checkbox and filter/sorted */}
        <Grid
          container
          xs={12}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid xs={6} sx={{ ml: 2, mr: -2 }}>
            <CustomCheckbox
              checked={selectedRows.size === rows.length && rows.length > 0}
              indeterminate={
                selectedRows.size > 0 && selectedRows.size < rows.length
              }
              onChange={handleSelectAll}
              name="selectAll"
            />
            <Typography
              sx={{
                display: "inline",
                ml: 5,
              }}
            >
              {selectedRows.size} of {filteredRows.length} selected
            </Typography>
          </Grid>

          {/* Remaining code for Filter, Sort, and Sorted Column Selection */}
          <Grid xs={6} container justifyContent="flex-end">
            <FilterButton onClick={handleFilterClick} />
            <SortButton
              onClick={handleSortedColumnClick}
              sortedColumn={sortedColumn ? { label: sortedColumn.label } : null}
            />

            <SortIconButton onClick={handleSortClick} sortConfig={sortConfig} />
            <RefreshIconButton onClick={handleRefresh} />
          </Grid>
        </Grid>

        {/* Popover Components */}
        <ExportPopover
          anchorEl={exportAnchorEl}
          onClose={handleExportClose}
          rows={rows}
          filteredRows={filteredRows}
          selectedRows={seletedRowsData}
        />
        <SortedColumnPopover
          sortedColumnAnchorEl={sortedColumnAnchorEl}
          handleSortedColumnClose={handleSortedColumnClose}
          setSortedColumn={setSortedColumn}
          sortedColumn={sortedColumn}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
        />
        <FilterOptionsPopover />

        <ColumnFilterPopover
          columnAnchorEl={columnAnchorEl}
          handleColumnClose={handleColumnClose}
          handleColumnSelect={handleColumnSelect}
          selectedColumns={selectedColumns}
        />

        {/* Dialog Component */}
        <Dialog open={isGraphOpen} onClose={closeGraph} fullWidth maxWidth="lg">
          <DialogTitle>
            Summary
            <IconButton
              style={{ position: "absolute", right: "8px", top: "8px" }}
              onClick={closeGraph}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <SummaryGraph
              rows={filteredRows}
              startDateCompleted={startDateCompleted}
              handleStartDateChangeCompleted={handleStartDateChangeCompleted}
              endDateCompleted={endDateCompleted}
              handleEndDateChangeCompleted={handleEndDateChangeCompleted}
              startDateSubmitted={startDateSubmitted}
              handleStartDateChangeSubmitted={handleStartDateChangeSubmitted}
              endDateSubmitted={endDateSubmitted}
              handleEndDateChangeSubmitted={handleEndDateChangeSubmitted}
              filters={filters}
              setFilters={setFilters}
              clearFilters={clearFilters}
            />
          </DialogContent>
        </Dialog>
      </Grid>
    </FilterOptionsContext.Provider>
  );
};

export default Controls;
