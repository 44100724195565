import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";

import { FileUploadOutlined, FilterAltOutlined } from "@mui/icons-material";

import { Dayjs } from "dayjs";
import { FilterOptionsContext } from "../../../../../contexts/FilterOptionsContext/FilterOptionsContext";
import {
  processErrorSuccessLine,
  processIncorrectFormsLine,
} from "../../../Utils/graphFormatting";
import { LogVirtuosoTableData } from "../../../Utils/tableDataFetch";
import ExportGraphPopover from "../Popovers/ExportGraphPopover";
import FilterOptionsPopover from "../Popovers/FilterOptionsPopover";
import LineGraph from "./Graphs/LineGraph";
import PieGraph from "./Graphs/PieGraph";

interface GraphProps {
  rows: LogVirtuosoTableData[];
  startDateCompleted: Dayjs | null;
  handleStartDateChangeCompleted: (date: Dayjs | null) => void;
  endDateCompleted: Dayjs | null;
  handleEndDateChangeCompleted: (date: Dayjs | null) => void;
  startDateSubmitted: Dayjs | null;
  handleStartDateChangeSubmitted: (date: Dayjs | null) => void;
  endDateSubmitted: Dayjs | null;
  handleEndDateChangeSubmitted: (date: Dayjs | null) => void;

  filters: {
    success: boolean;
    errors: boolean;
    last1DayCompleted: boolean;
    last7DaysCompleted: boolean;
    last1DaySubmitted: boolean;
    last7DaysSubmitted: boolean;
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      success: boolean;
      errors: boolean;
      last1DayCompleted: boolean;
      last7DaysCompleted: boolean;
      last1DaySubmitted: boolean;
      last7DaysSubmitted: boolean;
    }>
  >;
  clearFilters: () => void;
}

/*
 * This component is used to display the graphs and choices between different datasets.
 * Current contains pie and line graph views for two different datasets
 * It is used in the TableOptions component
 */
function SummaryGraph({
  rows,
  startDateCompleted,
  handleStartDateChangeCompleted,
  endDateCompleted,
  handleEndDateChangeCompleted,
  startDateSubmitted,
  handleStartDateChangeSubmitted,
  endDateSubmitted,
  handleEndDateChangeSubmitted,
  filters,
  setFilters,
  clearFilters,
}: GraphProps) {
  const theme = useTheme();
  const dataViewOptions = [
    {
      value: "Total vs Success and Incorrect Form",
      label: "incorrectforms",
    },
    { value: "Total vs Errors and Success", label: "errorsuccess" },
    //{ value: "Error Types", label: "errortypes" },
  ];
  const chartTypes = ["Line", "Pie"];

  const [selectedDataViewOption, setSelectedDataViewOption] = useState(
    dataViewOptions[0].label
  );

  const [exportAnchorEl, setExportAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLElement | null>(
    null
  );

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const [selectedChartType, setSelectedChartType] = useState(chartTypes[0]);

  const handleDataViewChange = (event: any) => {
    setSelectedDataViewOption(event.target.value);
  };

  const handleChartTypeChange = (event: any) => {
    setSelectedChartType(event.target.value);
  };

  const handleExportClick = (event: React.MouseEvent<HTMLElement>) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const processDataForChart = (rows: LogVirtuosoTableData[]) => {
    if (selectedDataViewOption === "errorsuccess") {
      return rows;
    }
    if (selectedDataViewOption === "errortypes") {
      //filter rows that dont equal "succeeded"
      const errorRows = rows.filter((row) => row.status !== "succeeded");
      return errorRows;
    } else if (selectedDataViewOption === "incorrectforms") {
      return rows;
    }
  };

  const exportData = (rows: LogVirtuosoTableData[]) => {
    let dataToExport;

    if (selectedDataViewOption === "errorsuccess") {
      const lineData = processErrorSuccessLine(rows);
      dataToExport = { lineData };
    } else if (selectedDataViewOption === "incorrectforms") {
      const lineData = processIncorrectFormsLine(rows);
      dataToExport = { lineData };
    }

    return dataToExport;
  };
  const chartData = processDataForChart(rows) || [];

  return (
    <FilterOptionsContext.Provider
      value={{
        startDateCompleted,
        handleStartDateChangeCompleted,
        endDateCompleted,
        handleEndDateChangeCompleted,
        startDateSubmitted,
        handleStartDateChangeSubmitted,
        endDateSubmitted,
        handleEndDateChangeSubmitted,
        filters,
        setFilters,
        clearFilters,
        filterAnchorEl,
        handleFilterClose,
      }}
    >
      <Grid
        container
        xs={12}
        md={12}
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%", mt: 1 }}
      >
        <Grid xs={8} md={8} container justifyContent="flex-start">
          {/* Dropdowns for Data View and Chart Type */}
          <Grid xs={6} md={6}>
            <FormControl fullWidth>
              <InputLabel sx={{ position: "relative", mb: -2, ml: -1 }}>
                Data View
              </InputLabel>
              <Select
                value={selectedDataViewOption}
                onChange={handleDataViewChange}
              >
                {dataViewOptions.map((option) => (
                  <MenuItem key={option.value} value={option.label}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={6} md={6} sx={{ pl: 1 }}>
            <FormControl fullWidth>
              <InputLabel sx={{ position: "relative", mb: -2, ml: -1 }}>
                Chart Type
              </InputLabel>
              <Select
                value={selectedChartType}
                label="Chart Type"
                onChange={handleChartTypeChange}
              >
                {chartTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid xs={4} md={4} container justifyContent="flex-end" sx={{ mb: -2 }}>
          <Button
            startIcon={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pb: "2px",
                }}
              >
                <FilterAltOutlined />
              </Box>
            }
            sx={{
              pr: 4,
              height: "50px",
              color: theme.palette.mode === "dark" ? "white" : "black",
            }}
            onClick={handleFilterClick}
          >
            Filter
          </Button>
          <Button
            variant="contained"
            onClick={handleExportClick}
            sx={{
              ml: 1,
              color: theme.typography.button.color,
              height: "50px",
            }}
            startIcon={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pb: "2px",
                }}
              >
                <FileUploadOutlined />
              </Box>
            }
          >
            Export
          </Button>
        </Grid>

        {/* Line Chart */}
        <Grid xs={12} sx={{ mt: 8, height: "100%" }}>
          {selectedChartType === "Line" ? (
            <LineGraph data={chartData} dataView={selectedDataViewOption} />
          ) : (
            <PieGraph data={chartData} dataView={selectedDataViewOption} />
          )}
        </Grid>

        {/* Popover for Export */}
        <ExportGraphPopover
          anchorEl={exportAnchorEl}
          onClose={handleExportClose}
          dataToExport={exportData(rows) || { lineData: [] }}
        />

        <FilterOptionsPopover />
      </Grid>
    </FilterOptionsContext.Provider>
  );
}

export default SummaryGraph;
