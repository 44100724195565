import { Box, CircularProgress, Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../contexts/DashboardContext/DashboardContext";
import { UserContext } from "../../contexts/UserContext/UserContext";

import Controls from "./Table/Controls/Controls";
import { Table } from "./Table/Table";
import { LogVirtuosoTableData, fetchData } from "./Utils/tableDataFetch";

export type TableColumn = {
  label: string;
  dataKey: string;
  numeric?: boolean;
  width?: string;
  formatter?: (value: any) => any;
};

/*
 * This component is used to display the dashboard, which contains the virtualized DashboardTable and TableOptions components.
 * It retreives data from the tableDataFetch utility and then displays either the child components or an error/loading message.
 * It is used in the App component.
 */
function Dashboard() {
  const user = useContext(UserContext).user;
  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState([]);
  const [error, setError] = useState<string | null>(null);
  const [tableRefresh, setTableRefresh] = useState(false);

  useEffect(() => {
    const fetchDataAsync = async () => {
      const result = await fetchData(user);
      setRows(result.data);
      setError(result.error);
      setLoading(false);
    };

    fetchDataAsync();
  }, [user]);

  const handleRefresh = async () => {
    setTableRefresh(true);
    const result = await fetchData(user);
    setRows(result.data);
    setError(result.error);
    setTableRefresh(false);
  };

  const [filteredRows, setFilteredRows] =
    useState<LogVirtuosoTableData[]>(rows);
  const [selectedRows, setSelectedRows] = useState(new Set<string>());
  const [sortedColumn, setSortedColumn] = useState<TableColumn | null>({
    label: "Submitted At",
    dataKey: "submittedAt",
  });
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: "asc" | "desc";
  }>({ key: "submittedAt", direction: "desc" });

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "93vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "93vh",
        }}
      >
        {error}
      </Box>
    );
  } else if (!rows.length) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "93vh",
        }}
      >
        No data to display
      </Box>
    );
  }

  return (
    <DashboardContext.Provider
      value={{
        filteredRows,
        setFilteredRows,
        setSelectedRows,
        selectedRows,
        sortedColumn,
        setSortedColumn,
        sortConfig,
        setSortConfig,
      }}
    >
      <Paper
        sx={{
          height: "90vh",
          minHeight: "850px",
          width: "100%",
          p: 0,
          borderRadius: 0,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#333" : "#f5f5f5",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "90%" }}
        >
          <Grid
            container
            xs={10}
            alignItems="center"
            sx={{ height: "100%", mb: 4 }}
          >
            {/* Table Filter Header*/}
            <Controls
              rows={rows}
              handleRefresh={handleRefresh}
              height={"17.5%"}
            />

            {/* Table Display */}
            <Grid
              sx={{
                height: "82.5%",
                backgroundColor: (theme) => theme.palette.background.default,
                mt: 2,
              }}
              xs={12}
            >
              <Table filteredRows={filteredRows} loading={tableRefresh} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </DashboardContext.Provider>
  );
}
export default Dashboard;
