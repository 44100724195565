// StatusBox.tsx
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { useState } from "react";

interface StatusBoxProps {
  status: "succeeded" | "n/a" | "error";
  statusValue: string;
}

const statusStyles = {
  succeeded: {
    backgroundColor: "#CCDBCD",
    color: "#5B805C",
  },
  "n/a": {
    backgroundColor: "#CFD0D3",
    color: "#53555A",
  },
  error: {
    backgroundColor: "#FFCCCC",
    color: "#C4363D",
  },
};

const StatusBox: React.FC<StatusBoxProps> = ({ status, statusValue }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleExpandClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            p: "5px",
            ...statusStyles[status],
          }}
        >
          <Typography sx={{ fontWeight: "bold", pl: 0.5, pr: 0.5 }}>
            {status === "n/a"
              ? status.toUpperCase()
              : status.charAt(0).toUpperCase() + status.slice(1)}
          </Typography>
        </Box>
        {status === "error" && (
          <IconButton
            size="small"
            onClick={handleExpandClick}
            sx={{ transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }}
          >
            <ExpandMoreIcon />
          </IconButton>
        )}
      </Box>
      <Box sx={{ pt: 1 }}>
        <Collapse in={isOpen}>
          <Typography sx={{ pl: 0.5, pr: 0.5 }}>{statusValue}</Typography>
        </Collapse>
      </Box>
    </>
  );
};

export default StatusBox;
