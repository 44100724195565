import React from "react";
import { TableColumn } from "../../components/Dashboard/Dashboard";
import { LogVirtuosoTableData } from "../../components/Dashboard/Utils/tableDataFetch";

interface DashboardProps {
  filteredRows: LogVirtuosoTableData[];
  setFilteredRows: React.Dispatch<React.SetStateAction<LogVirtuosoTableData[]>>;
  setSelectedRows: React.Dispatch<React.SetStateAction<Set<string>>>;
  selectedRows: Set<string>;
  sortedColumn: TableColumn | null;
  setSortedColumn: React.Dispatch<React.SetStateAction<TableColumn | null>>;
  sortConfig: { key: string | null; direction: "asc" | "desc" };
  setSortConfig: React.Dispatch<
    React.SetStateAction<{ key: string | null; direction: "asc" | "desc" }>
  >;
}

/*
 * This context is used to pass the dashboard state to the components that need it. Primarily for passing around the current filter config and filtered rows.
 * It is used in the Dashboard component.
 */
export const DashboardContext = React.createContext<DashboardProps>({
  filteredRows: [],
  setFilteredRows: () => {},
  setSelectedRows: () => {},
  selectedRows: new Set(),
  sortedColumn: null,
  setSortedColumn: () => {},
  sortConfig: { key: null, direction: "asc" },
  setSortConfig: () => {},
});
