import { Button, Box, useTheme } from "@mui/material";
import { Sort } from "@mui/icons-material";

interface SortButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  sortedColumn: { label: string } | null;
}

const SortButton = ({ onClick, sortedColumn }: SortButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      startIcon={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pb: "2px",
          }}
        >
          <Sort />
        </Box>
      }
      onClick={onClick}
      sx={{ color: theme.palette.text.primary, pr: 2 }}
    >
      Sort By {sortedColumn ? sortedColumn.label : ""}
    </Button>
  );
};

export default SortButton;
