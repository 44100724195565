import { Check, Remove } from "@mui/icons-material";
import { Box, Checkbox } from "@mui/material";

interface CustomCheckboxProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  indeterminate: boolean;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  onChange,
  name,
  indeterminate,
}) => {
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      name={name}
      indeterminate={indeterminate}
      icon={
        <Box
          sx={{
            border: "2px solid black",
            borderRadius: "3px",
            width: "20px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        ></Box>
      }
      checkedIcon={
        <Box
          sx={{
            border: "2px solid black",
            backgroundColor: "white",
            borderRadius: "3px",
            width: "20px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Check
            sx={{
              fontSize: "18px",
              color: "black",
            }}
          />
        </Box>
      }
      indeterminateIcon={
        <Box
          sx={{
            border: "2px solid black",
            backgroundColor: "white",
            borderRadius: "5px",
            width: "20px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Remove
            sx={{
              fontSize: "18px",
              color: "black",
            }}
          />
        </Box>
      }
    />
  );
};

export default CustomCheckbox;
