import { IconButton, useTheme } from "@mui/material";
import { Refresh } from "@mui/icons-material";

interface RefreshIconButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const RefreshIconButton = ({ onClick }: RefreshIconButtonProps) => {
  const theme = useTheme();

  return (
    <IconButton
      onClick={onClick}
      sx={{
        bgcolor: theme.palette.secondary.main,
        width: "35px",
        height: "35px",
        "&:hover": {
          bgcolor: theme.palette.secondary.main,
        },
        borderRadius: 3,
      }}
    >
      <Refresh sx={{ color: theme.typography.button.color }} />
    </IconButton>
  );
};

export default RefreshIconButton;
